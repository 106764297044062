
import { defineComponent } from "vue";
import ProfileImage from "@/components/ProfileImage.vue";
import store from "@/store";

export default defineComponent({
  name: "About",
  components: {
    ProfileImage,
  },
  methods: {
    getAge() {
      return store.state.age;
    },
  },
});
